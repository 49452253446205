<template>
  <div>
    <form @submit.prevent="submit">
      <AppModal v-model="model" :title="$t('ComponentMicroDepositModal.TitleModal').value">
        <template #header>
          <AppModalHeader>
            <h1>{{ $t('ComponentMicroDepositModal.Header').value }}</h1>

            <template #right>
              <AppBackButton
                analytics-name="micro-deposit-modal-back"
                @click="$router.toParentRoute()"
              />
            </template>
          </AppModalHeader>
        </template>

        <p class="info">
          {{ $t('ComponentMicroDepositModal.DescriptionPre').value }}
          <span class="font-bold">{{
            $t('ComponentMicroDepositModal.DescriptionCompany').value
          }}</span>
          {{ $t('ComponentMicroDepositModal.DescriptionPost').value }}
        </p>

        <AppInputText
          v-model="form.bankAccount"
          analytics-name="micro-deposit-modal-bankaccount"
          disabled
          :label="$t('ComponentMicroDepositModal.LabelBankAccount').value"
          class="card-name"
        />

        <AppInputText
          ref="userInputRef"
          v-model="validation.deposit1.$value"
          analytics-name="micro-deposit-modal-deposit1"
          :label="$t('ComponentMicroDepositModal.LabelDeposit1').value"
          type="text"
          placeholder="0.00"
          :validation="validation.deposit1"
          autofocus
        >
          <template #leftIcon>
            <AppIcon name=" ">
              <IconDollarSign />
            </AppIcon>
          </template>
        </AppInputText>

        <AppInputText
          ref="deposit2"
          v-model="validation.deposit2.$value"
          analytics-name="micro-deposit-modal-deposit2"
          :label="$t('ComponentMicroDepositModal.LabelDeposit2').value"
          type="text"
          placeholder="0.00"
          :validation="validation.deposit2"
          @focus="focusDeposit2"
        >
          <template #leftIcon>
            <AppIcon name=" ">
              <IconDollarSign />
            </AppIcon>
          </template>
        </AppInputText>

        <template #footer>
          <AppCardFooter>
            <AppButton
              theme="secondary"
              analytics-name="micro-deposit-modal-later"
              @click="$router.toParentRoute()"
              >{{ $t('ComponentMicroDepositModal.ButtonLater').value }}</AppButton
            >
            <AppButton
              type="submit"
              analytics-name="micro-deposit-modal-continue"
              :disabled="validation.$anyInvalid"
              :loading="loading"
            >
              {{ $t('ComponentMicroDepositModal.ButtonContinue').value }}
            </AppButton>
          </AppCardFooter>
        </template>
      </AppModal>
    </form>
    <!-- Failed attempt dialog -->
    <AppDialog v-model="isFailedAttemptDialogOpen">
      <template #header>
        <AppDialogHeader>
          <h3>
            {{ $t('ComponentMicroDepositModal.FailedAttemptHeader').value }}
          </h3>
        </AppDialogHeader>
      </template>
      <p>
        {{ $t('ComponentMicroDepositModal.FailedAttemptDescriptionPre').value }}
        {{ verificationCounter - 1 }}
        {{ $t('ComponentMicroDepositModal.FailedAttemptDescriptionPost').value }}
      </p>
      <template #footer>
        <AppDialogFooter>
          <AppButton
            type="button"
            analytics-name="micro-deposit-modal-try-again"
            @click="isFailedAttemptDialogOpen = false"
            >{{ $t('ComponentMicroDepositModal.ButtonTryAgain').value }}</AppButton
          >
        </AppDialogFooter>
      </template>
    </AppDialog>
  </div>
</template>

<script>
import { reactive, computed, ref, onMounted, toRef, watch } from '@vue/composition-api'

import { usePromiseLazy, useValidation } from 'vue-composable'
import { required, decimal } from '@vuelidate/validators'

import { useRouter } from '@galileo/composables/useRouter'
import {
  SEGMENT_EVENTS,
  SEGMENT_PAYMENT_METHOD_TYPES,
  SEGMENT_PAYMENT_METHOD_VERIFICATION_TYPES,
} from '@galileo/constants/segmentAnalytics'

import { IconDollarSign } from '@oen.web.vue2/icons'

import {
  useFocusUserInputRef,
  useVModel,
  useAlert,
  AppBackButton,
  AppModal,
  AppInputText,
  AppButton,
  AppModalHeader,
  AppIcon,
  AppCardFooter,
  AppDialog,
  AppDialogHeader,
  AppDialogFooter,
} from '@oen.web.vue2/ui'

import {
  useBankAccountStore,
  useAnalyticsStore,
  usePaymentsStore,
  useI18nStore,
} from '@galileo/stores'
import getValueRequiredValidation from '@galileo/utilities/validations.utility'

export default {
  name: 'MicrodepositModal',
  components: {
    AppModalHeader,
    AppButton,
    AppIcon,
    AppModal,
    AppInputText,
    AppCardFooter,
    AppDialog,
    AppDialogHeader,
    AppDialogFooter,
    IconDollarSign,
    AppBackButton,
  },
  emits: ['input'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const { model } = useVModel(props, emit)
    const bankAccountStore = useBankAccountStore()
    const analyticsStore = useAnalyticsStore()
    const router = useRouter()
    const { add: addAlert } = useAlert()
    const valueRequired = getValueRequiredValidation()
    const paymentsStore = usePaymentsStore()

    const paymentMethod = paymentsStore.selectedPaymentMethod
    const verificationCounter = computed(() =>
      paymentsStore.getBankVerificationCounter(paymentMethod.id)
    )

    const form = reactive({
      bankAccount: `${paymentMethod.title} ${paymentMethod.maskedAccountNumber}`,
      deposit1: '0.',
      deposit2: '',
    })
    const formatDeposit = (value) => {
      return value
        .replace(/^([1-9])$/g, '0.$1')
        .replace(/[^\d|^.]*/g, '') // replace all non digit char and full stop with empty string
        .replace(/\.\./g, '.') // prevent more than one full stop(.)
    }

    const { userInputRef, focus } = useFocusUserInputRef('.input-input')

    const focusDeposit2 = (event) => {
      if (form.deposit2.length < 3) {
        form.deposit2 = '0.'
        return
      }
      form.deposit2 = formatDeposit(event.target.value)
    }

    onMounted(() => {
      focus()
    })

    const isFailedAttemptDialogOpen = ref(false)
    const errorMessage = ref('')

    watch(
      computed(() => form.deposit1),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue.length < 3) {
            form.deposit1 = '0.'
          } else if (newValue.length > oldValue.length) {
            form.deposit1 = formatDeposit(newValue)

            form.deposit1 = form.deposit1.slice(0, 4)
          }
        }
      },
      { immediate: true }
    )

    watch(
      computed(() => form.deposit2),
      (newValue, oldValue) => {
        if (newValue !== oldValue) {
          if (newValue.length < 3) {
            form.deposit2 = '0.'
          } else if (newValue.length > oldValue.length) {
            form.deposit2 = formatDeposit(newValue)

            form.deposit2 = form.deposit2.slice(0, 4)
          }
        }
      }
    )

    const validation = useValidation({
      deposit1: {
        $value: toRef(form, 'deposit1'),
        valueRequired,
        decimal,
      },

      deposit2: {
        $value: toRef(form, 'deposit2'),
        valueRequired,
        decimal,
      },
    })

    const { result, exec, error, loading } = usePromiseLazy((arg) =>
      bankAccountStore.callVerifyBankAccount(arg)
    )

    const submit = async () => {
      await exec({
        accountId: paymentMethod.id,
        deposit1: form.deposit1,
        deposit2: form.deposit2,
      })
      if (result.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.BANK_VERIFICATION_COMPLETED,
          traits: {
            location: analyticsStore.paymentLocation,
            paymentVerificationMethod: SEGMENT_PAYMENT_METHOD_VERIFICATION_TYPES.MANUAL,
          },
        })
        analyticsStore.track({
          event: SEGMENT_EVENTS.NEW_PAYMENT_METHOD_COMPLETED,
          traits: {
            location: analyticsStore.paymentLocation,
            paymentMethod: SEGMENT_PAYMENT_METHOD_TYPES.BANK_ACCOUNT,
            paymentVerificationMethod: SEGMENT_PAYMENT_METHOD_VERIFICATION_TYPES.MANUAL,
          },
        })
        router.toParentRoute()
        addAlert($t('ComponentMicroDepositModal.AlertAdded').value)
      }

      //TODO: Temp error handling with counter saved into local storage.
      if (error.value) {
        const { friendlyMessage, message, key: errorKey } = error.value
        errorMessage.value = friendlyMessage ? friendlyMessage : message

        //refresh payment methods
        await paymentsStore.getPaymentMethods()
        paymentsStore.decrementVerificationCounter(paymentMethod)
        if (
          verificationCounter.value > 1 &&
          errorKey === 'MicroDepositVerificationFailedException'
        ) {
          isFailedAttemptDialogOpen.value = true
        } else if (
          verificationCounter.value === 1 &&
          errorKey === 'BankAccountFailedVerification'
        ) {
          router.push('bank-account-disabled')
        } else {
          router.push('bank-account-not-verified')
        }
      }
    }

    return {
      model,
      userInputRef,
      form,
      isFailedAttemptDialogOpen,
      submit,
      loading,
      focusDeposit2,
      validation,
      errorMessage,
      verificationCounter,
      $t,
    }
  },
}
</script>

<style scoped>
.info {
  @apply mb-6;
}

.card-name {
  /deep/ .input-input {
    @apply text-tertiary-text capitalize;
  }
}
</style>
